<template>
    <div class="pwd_container">
        <div class="pwd_box">
            <!-- 头像区域 -->
            <div class="avatar_box">
                <img src="../../assets/logo_1024.png" alt/>
            </div>
            <!-- 登录表单区域 -->
            <div class="pwd_alert">
                <el-alert v-if="resetType==0" title="忘记密码（通过邮箱验证码重置密码）" type="info" show-icon :closable="false"></el-alert>
                <el-alert v-else title="首次登录需要重置密码" type="warning" show-icon :closable="false"></el-alert>
            </div>
            <el-form
                    ref="pwdFormRef"
                    :model="pwdForm"
                    :rules="pwdFormRules"
                    label-width="100px"
                    class="pwd_form">
                <el-form-item prop="email" label="邮箱" v-if="resetType==0">
                    <el-input
                            v-model="pwdForm.email"
                            placeholder="邮箱">
                    </el-input>
                </el-form-item>
                <el-form-item prop="verifyCode" label="邮箱验证码" v-if="resetType==0">
                    <el-input
                            v-model="pwdForm.verifyCode"
                            placeholder="邮箱验证码">
                        <el-button :disabled="!emailBtnClick" class="email_verify_btn" slot="append"
                                   @click="sendEmailVerify" :loading="loading.emailBtn">{{emailSendText}}</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password" label="新密码" :rules="pwdFormRules.password">
                    <el-input
                            v-model="pwdForm.password"
                            placeholder="新密码"
                            type="new-password" :show-password="true"
                    ></el-input>
                    <span style="font-size: 10px;"><i class="el-icon-warning" style=" color: #e6a23c;"></i> 8-20位，由大写字母、小写字母、数字和英文字符(除空格外)至少三种组成</span>
                </el-form-item>
                <el-form-item prop="confirmPassword" label="确认密码" :rules="pwdFormRules.confirmPassword">
                    <el-input
                            v-model="pwdForm.confirmPassword"
                            placeholder="确认密码"
                            type="new-password" :show-password="true"
                    ></el-input>
                </el-form-item>
                <!--按钮区域-->
                <el-form-item class="pwd__btns-wapper" label-width="0">
                    <el-button class="pwd__btn"  @click="goBack">返回登录</el-button>
                    <el-button class="pwd__btn" type="primary" @click="resetPwdNoLogin">确认修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { resetPasswordAPI,getPasswordEmailNoAPI} from "@/api/login.js";

export default {
    data() {
        return {
            loading: {
              emailBtn: false
            },
            testStr: 1,
            resetType: 0,
            emailSendText: "发送验证码",
            emailBtnClick: true,
            // 这是登录表单的数据绑定对象
            pwdForm: {
                email: "",
                verifyCode: "",
                password: "",
                confirmPassword: "",
            },
            // 这是表单的验证规则对象
            pwdFormRules: {
                email: [{required: true,message: "请输入正确的邮箱格式", trigger: "blur", pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,}],
                verifyCode: [{required: true,message: "邮箱验证码必填", trigger: "blur"}],
                password: [{required: true,message: "密码必填", trigger: "blur"}],
                confirmPassword: [{required: true,message: "必填", trigger: "blur"}],
            },
        };
    },
    created() {
        this.resetType = this.$route.query.type ? this.$route.query.type : 0;
        this.pwdForm.password=''
        this.pwdForm.verifyCode=''
    },
    methods: {
        goBack(){
            this.$router.push("/login")
            this.clearForm()
        },
        clearForm(){
            Object.keys(this.pwdForm).forEach(key => {
                this.pwdForm[key] = '';
            })
        },
        async sendEmailVerify() {
            if(!this.pwdForm.email){
                return this.$message.error('请输入邮箱');
            }
             try {
                let _this = this;
                this.loading.emailBtn = true;
                let res = await getPasswordEmailNoAPI({email:this.pwdForm.email});
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '验证码获取成功!'
                    });
                    let time = 60;
                    let setTime = setInterval(function() {
                        _this.emailBtnClick = false;
                        time = time - 1;
                        if (time > 0) {
                            _this.loading.emailBtn = false;
                            _this.emailSendText = time + '秒后重发';
                        } else {
                            clearInterval(setTime); //清除js定时器
                            _this.emailSendText = '发送验证码';
                            _this.emailBtnClick = true;
                            time = 60; //重置时间
                        }
                    }, 1000);
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });
            } finally{
                this.loading.emailBtn = false;
            }
        },

        resetPwdNoLogin() {
            this.$refs.pwdFormRef.validate(async (valid, obj) => {
                if (!valid) return;
                if(this.pwdForm.password!=this.pwdForm.confirmPassword) {
                    return  this.$message.error('确认密码与新密码不一致');  
                }
                const res = await resetPasswordAPI({...this.pwdForm,menuCode:'change_my_password'});
                if (res.code == 0) {
                    this.$router.push("/login");
                    this.clearForm()
                } else {
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
            });
        },

    },
};
</script>

<style lang="scss" scoped>
.pwd_container {
    background-color: $color-bg-gray;
    height: 100%;
}

.pwd_box {
    width: 580px;
    //height: 340px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px #ddd;

    .avatar_box {
        height: 90px;
        width: 90px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
}

.pwd_alert {
    margin: 70px 60px 10px 60px;
    box-sizing: border-box;
}
.pwd_form {
    width: 100%;
    padding: 10px 60px;
    box-sizing: border-box;
}

.el-form {
    display: block;
}

.pwd__btns-wapper {
    display: flex;
    justify-content: space-around;
    margin: 25px 0;

    .pwd__btn {
        //margin: 0 50px;
        width: 100px;
    }
}

.reset_a {
    color: $color-a;
    position: fixed;
    right: 60px;
    font-size: $font-small;
}

.email_verify_btn {
    background: $color-theme-normal !important;
    color: #ffffff !important;
    border: 1px solid $color-theme-normal !important;
    border-radius: 0 !important;
    font-size: $font-small !important;
}
</style>
